export {Bingo} from './Bingo'
export {EGame} from './EGame'
export {FishingGame} from './FishingGame'
export {LiveCasino} from './LiveCasino'
export {Lotto} from './Lotto'
export {Slot} from './Slot'
export {Sport} from './Sport'
export {Table} from './Table'
export {Virtual} from './Virtual'
export {Chicken} from './Chicken'
export {Thai} from './Thai'