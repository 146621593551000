import React from 'react'

import { HomeList } from '../HomeList'

export const Lobby = () => {
  return (
    <>
      <HomeList />
    </>
  )
}
