// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown {
  z-index: 100;
  display: block;
  position: fixed;
}

.dropdown-content {
  position: absolute;
  background-color: rgba(44, 42, 74, 0.9);
  /* left: 112px; */
  top: 0;
  width: 0px;
  height: 100%;
  z-index: 1;
}

.dropdown-content div img {
 display: none;
}

.bonus-area:hover .dropdown-content div img {
  display: block;
}

.bonus-area:hover .dropdown-content {
  display: block;
  width: 200px;
  top: 0%;
  transition: 0.3s;
}

.dropdown-content:hover {
  display: block;
}

.content-slide .swiper {
  width: 100%;
  height: 100%;
}

.content-slide .swiper-slide {
  text-align: center;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-slide .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,uCAAuC;EACvC,iBAAiB;EACjB,MAAM;EACN,UAAU;EACV,YAAY;EACZ,UAAU;AACZ;;AAEA;CACC,aAAa;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,OAAO;EACP,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;;EAElB,iCAAiC;EACjC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;AACd","sourcesContent":[".dropdown {\n  z-index: 100;\n  display: block;\n  position: fixed;\n}\n\n.dropdown-content {\n  position: absolute;\n  background-color: rgba(44, 42, 74, 0.9);\n  /* left: 112px; */\n  top: 0;\n  width: 0px;\n  height: 100%;\n  z-index: 1;\n}\n\n.dropdown-content div img {\n display: none;\n}\n\n.bonus-area:hover .dropdown-content div img {\n  display: block;\n}\n\n.bonus-area:hover .dropdown-content {\n  display: block;\n  width: 200px;\n  top: 0%;\n  transition: 0.3s;\n}\n\n.dropdown-content:hover {\n  display: block;\n}\n\n.content-slide .swiper {\n  width: 100%;\n  height: 100%;\n}\n\n.content-slide .swiper-slide {\n  text-align: center;\n\n  /* Center slide text vertically */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.content-slide .swiper-slide img {\n  display: block;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
