import E1SPORTS from "../assets/img/Sport/E1SPORTS.png";
import SABA from "../assets/img/Virtual/SABA.png";
import SBO_SPORTSBET from "../assets/img/Sport/SBOBET_568Win_Sports.png";

export const Sport = [
    {
        imageUrl: E1SPORTS,
        platform: "E1SPORT",
    },
    {
        imageUrl: SABA,
        platform: "SABA",
    },
    {
        imageUrl: SBO_SPORTSBET,
        platform: "SBO_SPORTSBET",
    },
];
